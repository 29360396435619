/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb } from 'antd';
import logo from "../../assets/imgs/lhlogo.png"

function Introduction() {

    return (
        <div>
            <div className='container mx-auto xxxxl:w-full xxs:w-300 '>
                <div className='py-3.5 px-4 xs:-mx-4'>
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                title: <a href="/" className="hover:!text-colorPrimary hover:!bg-white">Trang chủ</a>,
                            },
                            {
                                title: <a href="/gioi-thieu" className="hover:!text-colorPrimary hover:!bg-white">Giới thiệu</a>,
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='container mx-auto xxxxl:w-full xxs:w-300 '>
                <div className='bg-white rounded-lg shadow-lg p-4'>
                    <div className='text-center mb-2 font-semibold text-colorSecondary text-4xl'>Lê Hiếu Computer</div>
                    <div className='text-center mb-2 font-semibold text-sm'>Hi-End Computer | Gaming Gear</div>
                    <div className='mb-4'>
                        <img src={logo} alt="" className='mx-auto w-80 h-70 max-w-full block' />
                    </div>
                    <div className='italic text-xl font-semibold mb-2'>Lời giới thiệu:</div>
                    <div className="text-sm">Lê Hiếu Computer xuất phát điểm từ một công ty bán buôn với đội ngũ hơn 10 năm kinh nghiệm tự tin khi nói Lê Hiếu Computer là một trong những đơn vị cung cấp các sản phẩm linh kiện máy tính lớn nhất miền bắc. Các sản phẩm bảo gồm linh kiện cho PC (Mainboard, CPU, RAM, VGA,…) cho tới Laptop, Máy in v.v.v.. Nhận ra phong trào Gaming gear, PC Gaming, Custom ngày càng phát triển rộng. Do đó, với sự tin tưởng của quý khách hàng trong nhiều năm hoạt động, nay Lê Hiếu Computer bắt đầu mở rộng thêm kinh doanh, bán lẻ các mặt hàng Gaming, Hi-end. Quý khách hàng yên tâm khi mua hàng khi PCM luôn đáp ứng các tiêu chí:</div>

                    <ul className="list-disc pl-6 my-10 font-semibold text-sm">
                        <li>Vui vẻ, Thân Thiện</li>
                        <li>Giá cả hợp lý</li>
                        <li>Uy tín hàng đầu</li>
                        <li>Nhiệt tình tư vấn</li>
                    </ul>

                    <div className="text-sm mb-4">Trong mảng Hi-End, Gaming gear với đội ngũ công ty trẻ trung năng động, luôn bắt kịp xu thế mới của thế giới ngay từ khi bắt đầu hoạt động. Việc làm này giúp thực hiện hóa ý tưởng cũng như nhu cầu của khách hàng một cách hợp lý nhất.</div>

                    <div className="text-sm mb-4">Lê Hiếu Computer luôn cố gắng phát triển về chất lượng và dịch vụ, khẳng định hàng hóa có nguồn gốc xuất xứ rõ ràng, chính hãng 100% với một mức chi phí tiết kiệm nhất.</div>

                    <div className="text-sm mb-4">Chân thành cảm ơn quý khách đã tin tưởng và ủng hộ Lê Hiếu Computer trong suốt thời gian qua và luôn sẵn sàng đón chờ sự trở lại của quý khách. Chúng tôi đang tiếp tục hàng ngày, hàng giờ cố gắng phát triển để đưa các sản phẩm tốt nhất tới tay Gamer Việt Nam.</div>

                    <div className="text-sm mb-4">Trân trọng cảm ơn và hân hạnh được phục vụ quý khách.</div>

                    <div className="mt-6 flex items-center">
                        <div className="text-sm font-bold mb-4 mr-2">Website:</div>
                        <div className="text-sm mb-4">https://lehieucomputer.com/</div>
                    </div>

                    <div className="mt-6">
                        <div className="text-sm font-bold mb-2">Địa Chỉ:</div>
                        <div className="text-sm mb-4">* 시흥시 정왕동 1417-7</div>
                    </div>

                    <div className="mt-6 flex items-center">
                        <div className="text-sm font-bold mb-4 mr-2">Hotline:</div>
                        <div className="text-sm mb-4">010-6222-1678                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction;

import React from 'react'
import ManageItems from '../manage-form';
import ManageItemsCollection from './manage-item-collection';

const ManageCollection
: React.FC = () => {
    return <ManageItemsCollection title="Danh mục" />;
};

export default ManageCollection


/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, Switch, Upload, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import ProductService from '../../../../services/product';
import NewsService from '../../../../services/news';
import { env } from 'process';
import BannerService from '../../../../services/banner';

interface Item {
    key: string;
    id: number;
    categoryName: string;
    categoryNameSlug: string;
    parentId: number;
    logo: File;
    status: string;
    subcategory: string;
    isShowHome: boolean;
    isShowMenu: boolean;
}


const ManageItemsBanner

    : React.FC<{ title: string }> = ({ title }) => {



        const [isModalOpen, setIsModalOpen] = useState(false);
        const [editingItem, setEditingItem] = useState<Item | null>(null);
        const [form] = Form.useForm();

        const [listBanner, setListBanner] = useState<any[]>([]);

        const [categoryById, setCategoryById] = useState<Item>();

        const getAllBanner = async () => {
            const result = await BannerService.getAllBanner();

            if (result.data.message === 'success') {
                const formattedData = await Promise.all(
                    result.data.data.map(async (banner: any, index: number) => {
                        return {
                            key: banner.id || index.toString(),
                            homeType: banner.homeType,
                            image: banner.image,
                            href: banner.href,
                            status: banner.status,

                        };
                    })
                );
                setListBanner(formattedData);
            }
        };


        const getCategoryById = async (id: number) => {
            const result = await ProductService.getCategoryById(id);
            if (result.response?.data.message === 'success') {
                setCategoryById(result.response?.data.data);
            }
        };

        useEffect(() => {
            getAllBanner();
        }, []);

        const [api, contextHolder] = notification.useNotification();




        const handleAdd = () => {
            setIsModalOpen(true);
            setEditingItem(null);
            form.resetFields();
        };

        const handleEdit = async (record: any) => {
            // console.log("record", record)
            const result = await BannerService.getBannerById(record.key);
            console.log("result", result)

            setFileName(record.image);

            const logoFileList = [{
                uid: `-1`,
                name: record.image.split('/').pop(),
                status: 'done',
                url: `https://api.lehieucomputer.com${record.image}`,
            }];
            console.log("logoFileList", logoFileList)
            setIsModalOpen(true);
            setEditingItem(result.response?.data.data);
            form.setFieldsValue({ ...result.response?.data.data, image: logoFileList });
        };

        const handleDelete = async (key: number) => {
            const obj = {
                id: key
            }
            console.log("obj", obj)
            const result = await BannerService.deleteBanner(obj);
            console.log("result Delete", result)
            if (result.response?.data.message === "success") {
                // alert("Xóa thành công")
                api.success({
                    message: 'Xóa banner thành công',
                    showProgress: true,
                    pauseOnHover: true,
                });
                window.location.reload();
            }
        };


        const { REACT_APP_API_URL } = process.env;
        const handleOk = () => {
            form.validateFields().then(async values => {
                values.image = `${fileName}`;
                if (editingItem !== null) {
                    Object.assign(values, {
                        id: editingItem.id,
                        homeType: values.homeType,
                        image: `${fileName}` || "",
                        href: values.href,
                        status: values.status,
                        orderBy: values.orderBy || 0
                    });
                    const result = await BannerService.updateBanner(values);

                    if (result.response?.data.message === "success") {
                        api.success({
                            message: 'Update banner thành công',
                            showProgress: true,
                            pauseOnHover: true,
                        });
                        window.location.reload();
                        // alert("Success")
                    }
                } else {
                    const result = await BannerService.createBanner(values);

                    if (result.response) {

                        api.success({
                            message: 'Thêm banner thành công',
                            showProgress: true,
                            pauseOnHover: true,
                        });
                        window.location.reload();
                    }
                    setIsModalOpen(false);
                }
            });
        };

        const handleCancel = () => {
            setIsModalOpen(false);
        };

        const columns = [
            {
                title: 'Loại Banner',
                dataIndex: 'homeType',
                key: 'homeType',
            },
            {
                title: 'Ảnh Banner',
                dataIndex: 'image',
                key: 'image',
                render: (image: any) => (
                    <img
                        src={`https://api.lehieucomputer.com${image}`}
                        alt="image"
                        style={{ width: '80px', height: '80px' }}
                    />
                ),
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                render: (status: any) => (
                    <span style={{ color: status === 'Active' ? 'green' : 'red', fontWeight: 'bold' }}>
                        {status === 'Active' ? 'Hoạt động' : 'Không hoạt động'}
                    </span>
                ),
            },
            {
                title: 'Href',
                dataIndex: 'href',
                key: 'href',
            },
            {
                title: 'Sửa/Xóa',
                key: 'actions',
                render: (_: any, record: any) => (
                    <span>
                        <Button type="link" onClick={() => handleEdit(record)}>
                            <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                        </Button>
                        <Popconfirm
                            title="Bạn có muốn xóa mục này không?"
                            onConfirm={() => handleDelete(record?.key)}
                        >
                            <Button type="link" danger>
                                <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ];

        const { Option } = Select;

        const [availableSubcategories, setAvailableSubcategories] = useState([]);

        // const handleCustomRequest = async ({ file }: any) => {
        //     try {
        //       const response = await ProductService.uploadFile(file);
        //       console.log("response",response)
        //     } catch (error) {

        //     }
        //   };

        const [fileName, setFileName] = useState("")

        const handleUpload = async (options: any) => {
            const { file, onSuccess } = options;

            const formData = new FormData();
            formData.append('file', file);

            const result = await NewsService.uploadFileNewsCMS(formData);

            if (result.response) {
                onSuccess(result.response.data);
                setFileName(result.response.data.data);
            }
        };

        console.log("fileName", fileName)

        return (
            <div>
                {contextHolder}
                <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                    Thêm banner
                </Button>
                <Table columns={columns} dataSource={listBanner} />

                <Modal title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
                    <Form form={form} layout="vertical">
                        <Form.Item name="homeType" label="Kiểu banner" rules={[{ required: true, message: 'Hãy nhập kiểu banner!' }]}>
                            <Select placeholder="Chọn kiểu banner">
                                <Select.Option value={"BANNER_TOP"}>BANNER_TOP</Select.Option>
                                <Select.Option value={"BANNER_FIX"}>BANNER_FIX</Select.Option>
                                <Select.Option value={"BANNER_FIRST"}>BANNER_FIRST</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="image"
                            label="Ảnh banner"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e && e.fileList;
                            }}
                            rules={[{ required: false, message: 'Hãy nhập ảnh banner!' }]}
                        >
                            <Upload
                                name="image"
                                listType="picture"
                                customRequest={handleUpload}
                                maxCount={1}
                            >
                                <Button>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item name="status" label="Trạng thái" rules={[{ required: true, message: 'Hãy nhập trạng thái!' }]}>
                            <Select placeholder="Chọn trạng thái">
                                <Select.Option value={"Active"}>Active</Select.Option>
                                <Select.Option value={"Deactive"}>Deactive</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="href" label="Href" rules={[{ required: false, message: 'Hãy chọn href!' }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    };

export default ManageItemsBanner;

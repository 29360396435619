import React, { useState } from 'react';

interface ImageModalProps {
    arrayImage: { image: string } | null; // Kiểu dữ liệu cho object
}

const ImageModal: React.FC<ImageModalProps> = ({ arrayImage }) => {
    const [showImage, setShowImage] = useState(true);

    // console.log(arrayImage, 'bannerFirst');

    const handleClose = () => {
        setShowImage(false);
    };

    const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    if (!showImage || !arrayImage) return null;

    return (
        <div
            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
            onClick={handleOutsideClick}
        >
            <div className="">
                <img
                    src={`https://api.lehieucomputer.com${arrayImage.image}`}
                    alt="Welcome"
                    className="max-w-full max-h-full relative"
                />

                <button
                    onClick={handleClose}
                    className="absolute right-2 top-2 bg-black hover:bg-white hover:text-black duration-300 cursor-pointer text-white rounded-full px-3 py-1 font-semibold"
                >
                    X
                </button>
            </div>
        </div>
    );
};

export default ImageModal;

import React from "react";

const SettingIconComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14.5713C13.4201 14.5713 14.5714 13.4201 14.5714 11.9999C14.5714 10.5797 13.4201 9.42847 12 9.42847C10.5798 9.42847 9.42856 10.5797 9.42856 11.9999C9.42856 13.4201 10.5798 14.5713 12 14.5713Z"
        stroke="#565D6D"
        strokeWidth="2.05714"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18.8572 12.0001C18.8561 11.5063 18.8021 11.0141 18.696 10.5318L21.0223 8.77039L19.308 5.80125L16.614 6.93782C15.8845 6.26899 15.0186 5.7663 14.076 5.46439L13.7143 2.57153L10.2857 2.57153L9.92401 5.46439C8.98145 5.7663 8.11555 6.26899 7.38601 6.93782L4.69201 5.80125L2.97772 8.77039L5.30401 10.5318C5.0892 11.4989 5.0892 12.5013 5.30401 13.4684L2.97772 15.2298L4.69201 18.199L7.38601 17.0624C8.11555 17.7312 8.98145 18.2339 9.92401 18.5358L10.2857 21.4287H13.7143L14.076 18.5358C15.0186 18.2339 15.8845 17.7312 16.614 17.0624L19.308 18.199L21.0223 15.2298L18.696 13.4684C18.8021 12.9861 18.8561 12.4939 18.8572 12.0001Z"
        stroke="#565D6D"
        strokeWidth="2.05714"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default SettingIconComponent;

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import FooterFormComponent from '../page-components/footer/footer-frm';
import HeaderFormComponent from '../page-components/header/header-frm';
import { FloatButton, Input, Space } from 'antd';
import UserIconComponent from '../assets/icons/UserIcon';
import FacebookIconComponent from '../assets/icons/FacebookIcon';
import CommentIconComponent from '../assets/icons/CommentIcon';
import PhoneIconComponent from '../assets/icons/PhoneIcon';
import YoutubeIconComponent from '../assets/icons/YoutubeIcon';
import MessageSendIcon from '../assets/icons/MessageSent';
import TiktokIconComponent from '../assets/icons/TiktokIcon';
import ZaloIconComponent from '../assets/icons/ZaloIcon';
import LocationIconComponent from '../assets/icons/LocationIcon';
import HomeService from '../services/home';

const { Search } = Input;


const ClientLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [cateId, setCateId] = useState('');
    const [query, setQuery] = useState('');
    const [dataSocial, setDataSocial] = useState([]);
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };
    const BUTTON_HEIGHT = 50;
    const INITIAL_TOP_POSITION = 280;
    const handleSearch = () => {
        if (query !== '') {
            window.location.href = `/tim-kiem?scat_id=${cateId}&q=${query}`;
        }
    };

    const fetchSocialLink = async () => {
        const response = await HomeService.getLinkSocial("LINK_SOCIAL");

        if (response.response?.data.message === 'success') {
            setDataSocial(response.response?.data.data);
            // console.log(response.response?.data.data, 'data')
        }

    };

    useEffect(() => {
        fetchSocialLink();
    }, []);

    useEffect(() => {
        const buttons = document.querySelectorAll('.floating-button');
        if (buttons.length > 0) {
            setTimeout(() => {
                buttons.forEach((button, index) => {
                    setTimeout(() => {
                        button.classList.add('animate-slideIn');
                    }, index * 500);
                });
            }, 1000);
        }
    }, [dataSocial]);


    return (
        <div>
            <HeaderFormComponent />
            <div className='mx-auto container '>
                <div className='py-2 bg-white md:hidden block px-4 xs:-mx-4 '>
                    <Space.Compact className='w-full'>
                        <Search
                            placeholder="Tìm kiếm sản phẩm..."
                            allowClear size='large'
                            value={query}
                            onChange={handleSearchChange}
                            onSearch={handleSearch}
                        />
                    </Space.Compact>
                </div>
            </div>

            <main className='bg-gradient-to-r from-colorLinearF to-colorLinearT pb-8'>
                {children}
                <div className="fixed right-0 top-36 z-10">
                    <a
                        className="floating-button transform translate-x-full opacity-0 absolute right-0"
                        style={{ top: '230px' }}
                        href='/thong-tin-lien-he'
                        target='_blank'
                    >
                        <FloatButton
                            icon={<LocationIconComponent style='w-5 h-5 stroke-colorSecondary' />}
                        />
                    </a>
                    {dataSocial.map((item: any, index: number) => (
                        <a
                            key={index}
                            href={`${item.href}`}
                            className="floating-button transform translate-x-full opacity-0 absolute right-0"
                            style={{ top: `${INITIAL_TOP_POSITION + index * BUTTON_HEIGHT}px` }}
                            target='_blank'
                        >
                            {item.text === "Facebook" ? (
                                <FloatButton
                                    icon={<FacebookIconComponent style='w-5 h-5 fill-white' />}
                                    type="primary"
                                    href=''
                                />
                            ) : item.text === "Youtube" ? (
                                <FloatButton
                                    icon={<YoutubeIconComponent style='w-5 h-5 fill-colorThird' />}
                                />
                            ) : item.text === "Tiktok" ? (
                                <FloatButton
                                    icon={<TiktokIconComponent style='w-5 h-5' />}
                                />
                            ) : item.text === "Zalo" ? (
                                <FloatButton
                                    icon={<ZaloIconComponent style='w-5 h-5 fill-colorSecondaryHigher' />}
                                />
                            ) : null}
                        </a>
                    ))}

                    <FloatButton.BackTop />
                </div>
            </main>

            <FooterFormComponent />
        </div>
    );
};

export default ClientLayout;

/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Input, Button, Breadcrumb, notification } from 'antd';
import { RuleObject } from 'antd/lib/form';
import React from 'react'

function Contact() {
    const [form] = Form.useForm();

    const validateMessages = {
        required: '${label} là bắt buộc!',
        types: {
            email: '${label} không hợp lệ!',
            number: '${label} không hợp lệ!',
        },
        pattern: {
            mismatch: '${label} không hợp lệ!',
        },
    };

    const onFinish = (values: any) => {
        // console.log('Success:', values);
        notification.success({
            message: 'Thành công',
            description: 'Thông tin của bạn đã được gửi thành công.',
            placement: 'topRight',
        });
        form.resetFields();
    };

    const phoneValidationRule: RuleObject = {
        pattern: /^[0-9]{10,11}$/,
        message: 'Điện thoại phải là số và có 10 hoặc 11 chữ số!',
    };
    return (
        <div>
            <div className='container mx-auto xxxxl:w-full xxs:w-300 '>
                <div className='py-3.5 px-4 xs:-mx-4'>
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                title: <a href="/" className="hover:!text-colorPrimary hover:!bg-white">Trang chủ</a>,
                            },
                            {
                                title: <a href="/thong-tin-lien-he" className="hover:!text-colorPrimary hover:!bg-white">Thông tin liên hệ</a>,
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='container mx-auto xxxxl:w-full xxs:w-300 '>
                <div className='py-5 bg-white px-4 rounded-lg shadow-lg flex flex-wrap justify-between'>
                    <div className='lg:w-49.5% w-full lg:mb-0 mb-5 text-center'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3170.302536630881!2d126.85624030000001!3d37.382677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b64326943244b%3A0xc2e738c6554910b0!2s189%20Jonam-dong%2C%20Siheung-si%2C%20Gyeonggi-do%2C%20H%C3%A0n%20Qu%E1%BB%91c!5e0!3m2!1svi!2s!4v1725637748756!5m2!1svi!2s"
                            loading="lazy" className='block w-full shadow-md rounded-lg overflow-hidden max-w-full h-110'></iframe>
                    </div>
                    <div className='lg:w-49.5% w-full'>
                        <div className='mb-4 text-sm'>Mọi thắc mắc hoặc góp ý, quý khách vui lòng liên hệ trực tiếp với bộ phận chăm sóc khách hàng của chúng tôi bằng cách điền đầy đủ thông tin vào form bên dưới</div>
                        <div>
                            <Form
                                form={form}
                                name="contactForm"
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                labelAlign="left"
                            >
                                <Form.Item
                                    label="Tên đầy đủ"
                                    name="fullname"
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder="Nhập tên đầy đủ của bạn" />
                                </Form.Item>

                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, type: 'email' }]}
                                >
                                    <Input placeholder="Nhập email của bạn" />
                                </Form.Item>

                                <Form.Item
                                    label="Điện thoại"
                                    name="phone"
                                    rules={[{ required: true }, phoneValidationRule]}
                                >
                                    <Input placeholder="Nhập số điện thoại của bạn" />
                                </Form.Item>

                                <Form.Item
                                    label="Thông tin liên hệ"
                                    name="contactInfo"
                                    rules={[{ required: true }]}
                                >
                                    <Input.TextArea rows={4} placeholder="Nhập thông tin liên hệ của bạn" />
                                </Form.Item>
                                <div className='sm:block hidden'>
                                    <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="w-full !cursor-pointer"
                                        >
                                            Gửi liên hệ
                                        </Button>
                                    </Form.Item>
                                </div>
                                <div className='sm:hidden block'>
                                    <Form.Item wrapperCol={{ span: 18 }}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="w-full !cursor-pointer"
                                        >
                                            Gửi liên hệ
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, notification, Tooltip } from 'antd';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import FAQCMSService from '../../../../services/faq';
import QuillEditorWrapper from '../../../../components/quill-editor/quill-editor.component';

const { Option } = Select;

const ManageFAQ = () => {
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = (record: any) => {
        // console.log(record.id, 'id')
        setIsModalOpen(true);
        setEditingItem(record.id);
        form.setFieldsValue(record);
    };

    const handleDelete = async (key: number) => {
        const obj = {
            id: key
        }
        const result = await FAQCMSService.deleteFAQCMS(obj);
        // console.log(result.response?.data, 'result form')
        if (result.response?.data.message === "success") {
            api.success({
                message: 'Xóa thành công người dùng!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
        fetchFAQCMS();
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            let result;

            if (editingItem) {
                // Updating existing FAQ
                const obj = {
                    id: editingItem,
                    answer: values.answer,
                    question: values.question,
                    status: values.status
                };
                result = await FAQCMSService.updateFAQCMS(obj);
            } else {
                // Creating new FAQ
                result = await FAQCMSService.createFAQCMS(values);
            }

            if (result.response?.data.message === "success") {
                api.success({
                    message: editingItem ? 'Cập nhật thành công!' : 'Tạo thành công người dùng mới!',
                    showProgress: true,
                    pauseOnHover: true,
                });
            } else {
                api.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.response?.data.message || 'Unknown error',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }

            fetchFAQCMS();
            setIsModalOpen(false);
        } catch (error) {

            api.error({
                message: 'Vui lòng nhập đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };



    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchFAQCMS = async () => {

        try {
            const result = await FAQCMSService.getListFAQCMS();
            // console.log(result.data, 'result')
            if (result.data.message === 'success') {
                setData(result.data.data);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e: any) => {
        setTooltipPosition({
            x: e.clientX,
            y: e.clientY,
        });
    };
    useEffect(() => {
        fetchFAQCMS();
    }, []);

    const columns = [

        {
            title: 'Câu hỏi',
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: 'Câu trả lời',
            dataIndex: 'answer',
            key: 'answer',
            ellipsis: true,
            render: (text: any) => (
                <span>
                    {text ? 'Có' : 'Không'}
                </span>
            ),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                return status === 'Active' ? 'Đã xác nhận' : 'Chưa xác nhận';
            },
        },
        {
            title: '',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link"
                        onClick={() => handleEdit(record)}
                    >
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Thêm mới FAQs
            </Button>
            <Table columns={columns} dataSource={data} rowKey="id" />

            <Modal
                destroyOnClose={false}
                forceRender={true}
                title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'}
                open={isModalOpen}
                onOk={handleOk}
                okText={editingItem ? 'Lưu chỉnh sửa' : 'Thêm mới ngay'}
                cancelText="Hủy"
                width={1000}
                onCancel={handleCancel}>
                <Form form={form} layout="vertical">

                    <Form.Item name="question" label="Câu hỏi" rules={[{ required: true, message: 'Hãy nhập câu hỏi!' }]}>
                        <Input />
                    </Form.Item>
                    {/* <Form.Item name="answer" label="Câu trả lời" rules={[{ required: true, message: 'Hãy nhập câu trả lời!' }]}>
                        <Input />
                    </Form.Item> */}
                    <Form.Item
                        name="answer"
                        label="Câu trả lời"
                        rules={[{ required: true, message: 'Hãy nhập câu trả lời!' }]}
                    >
                        <QuillEditorWrapper
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Trạng thái"
                        rules={[{ required: true, message: 'Hãy chọn trạng thái!' }]}
                    >
                        <Select placeholder="Chọn trạng thái">
                            <Option value="Active">Đã xác nhận</Option>
                            <Option value="Deactivate">Chưa xác nhận</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageFAQ;

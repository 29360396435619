import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { IPermissionsResult, IUser } from "../interfaces";

interface IProps {
  children: any;
  permissions?: (user: IUser) => IPermissionsResult;
}

const RequireAuth = ({ children, permissions }: IProps) => {
  const { auth }: any = useAuth();
  const location = useLocation();

  if (!auth?.access_token) {
    console.log("aa", auth);
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }

  if (auth?.loading) {
    // return <div>Loading auth.............................</div>;
  }

  // if (!auth?.user) {
  //   console.log('aa', auth)

  //   return <Navigate to={"/login"} state={{ from: location }} replace />;
  // }

  if (permissions) {
    const { isAllow, callback }: IPermissionsResult = permissions(auth?.user);
    if (!isAllow) {
      return callback ? (
        callback(auth?.user)
      ) : (
        <Navigate to={"/unauthorized"} state={{ from: location }} replace />
      );
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default RequireAuth;

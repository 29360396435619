import React from 'react'

interface IConProps {
    style?: string;
}

const SmartPhoneIconComponent: React.FC<IConProps> = ({ style }) => {
    return (
        <svg className={style} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512" xmlSpace="preserve">
            <g>
                <g>
                    <g>
                        <path d="M196.267,42.667h42.667c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533h-42.667
				c-4.713,0-8.533,3.821-8.533,8.533S191.554,42.667,196.267,42.667z"/>
                        <path d="M162.133,42.667h8.533c4.713,0,8.533-3.82,8.533-8.533s-3.821-8.533-8.533-8.533h-8.533
				c-4.713,0-8.533,3.821-8.533,8.533S157.42,42.667,162.133,42.667z"/>
                        <path d="M452.267,93.867H349.866V59.733V33.809C349.866,15.136,334.73,0,316.057,0H93.542C74.868,0,59.733,15.136,59.733,33.809
				v25.924V76.8c-4.712,0-8.532,3.821-8.532,8.533v8.533c0,4.712,3.82,8.533,8.532,8.533v8.533c-4.712,0-8.532,3.821-8.532,8.533
				V128c0,4.713,3.82,8.533,8.532,8.533v8.533c-4.712,0-8.532,3.821-8.532,8.533v8.533c0,4.713,3.82,8.533,8.532,8.533V435.2v42.991
				c0,18.673,15.136,33.809,33.809,33.809h222.515c18.673,0,33.809-15.136,33.809-33.809V435.2V281.6h102.401
				c4.713,0,8.533-3.821,8.533-8.533V102.4C460.8,97.687,456.98,93.867,452.267,93.867z M93.542,17.067h222.515
				c9.248,0,16.742,7.495,16.742,16.742V51.2h-256V33.809C76.799,24.561,84.294,17.067,93.542,17.067z M76.799,478.191v-34.458
				h105.435c-7.09,6.254-11.567,15.401-11.567,25.6s4.477,19.346,11.567,25.6H93.542C84.294,494.933,76.799,487.439,76.799,478.191z
				M204.8,486.4c-9.427,0-17.067-7.64-17.067-17.067s7.64-17.067,17.067-17.067s17.067,7.64,17.067,17.067
				S214.227,486.4,204.8,486.4z M316.057,494.933h-88.691c7.09-6.254,11.567-15.401,11.567-25.6s-4.477-19.346-11.567-25.6h105.433
				v34.458C332.799,487.439,325.304,494.933,316.057,494.933z M332.799,426.667h-256v-358.4h256v25.6H230.4h-0.001
				c-4.713,0-8.533,3.82-8.533,8.533v170.667c0,4.713,3.82,8.533,8.533,8.533h0.001h28.62l14.921,37.302
				c1.43,3.576,4.677,5.364,7.923,5.364c3.246,0,6.493-1.788,7.923-5.364l14.921-37.302h28.091V426.667z M443.733,264.533H341.332
				h-42.401h-0.001c-3.489,0-6.627,2.124-7.923,5.364l-9.143,22.858l-9.143-22.858c-1.296-3.24-4.434-5.364-7.923-5.364h-0.001
				h-25.864v-153.6h102.399h102.401V264.533z"/>
                        <path d="M281.6,162.133c-14.142,0-25.6,11.458-25.6,25.6s11.458,25.6,25.6,25.6s25.6-11.458,25.6-25.6
				S295.742,162.133,281.6,162.133z M281.6,196.267c-4.716,0-8.533-3.817-8.533-8.533s3.817-8.533,8.533-8.533
				c4.717,0,8.533,3.817,8.533,8.533S286.317,196.267,281.6,196.267z"/>
                        <path d="M315.733,187.733c0,14.142,11.458,25.6,25.6,25.6s25.6-11.458,25.6-25.6s-11.458-25.6-25.6-25.6
				S315.733,173.591,315.733,187.733z M341.333,179.2c4.716,0,8.533,3.817,8.533,8.533s-3.817,8.533-8.533,8.533
				s-8.533-3.817-8.533-8.533S336.617,179.2,341.333,179.2z"/>
                        <path d="M401.067,213.333c14.142,0,25.6-11.458,25.6-25.6s-11.458-25.6-25.6-25.6s-25.6,11.458-25.6,25.6
				S386.925,213.333,401.067,213.333z M401.067,179.2c4.716,0,8.533,3.817,8.533,8.533s-3.817,8.533-8.533,8.533
				s-8.533-3.817-8.533-8.533S396.35,179.2,401.067,179.2z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SmartPhoneIconComponent
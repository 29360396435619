import React from "react";

const EditIconComponent = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.28571 13.7144L13.7143 13.7144"
        stroke="#171A1F"
        strokeWidth="1.37143"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.2857 10.8572L3.42856 11.4286L3.99999 8.5715L10.1183 2.45322C10.3417 2.22979 10.7034 2.22979 10.9263 2.45322L12.404 3.93093C12.6274 4.15436 12.6274 4.51607 12.404 4.73893L6.2857 10.8572Z"
        stroke="#171A1F"
        strokeWidth="1.37143"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M8.57144 4L10.8572 6.28571"
        stroke="#171A1F"
        strokeWidth="1.37143"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default EditIconComponent;

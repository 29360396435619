/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";

const getListCustomerCMS = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminCustomer/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const createCustomerCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminCustomer/create`, obj);
        if (response) {
            return {
                response,
                message: "Create User successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const updateCustomerCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminCustomer/update`, obj);
        if (response) {
            return {
                response,
                message: "Update User successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const deleteCustomerCMS = async (id: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminCustomer/delete`, id);
        if (response) {
            return {
                response,
                message: "Delete User successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};


const CustomerCMSService = {
    getListCustomerCMS,
    createCustomerCMS,
    updateCustomerCMS,
    deleteCustomerCMS,
};

export default CustomerCMSService;

import { all, put, take, takeEvery } from "redux-saga/effects";
import { addTodo, showTodoCongratulationAction } from "../slices/todoSlice";
import { sagaSetDataWatcher } from "./saga-socket";

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
export function* helloSaga() {
  yield delay(5000);
  console.log("Hello Sagas!");
}

function* watchAndLog() {
  yield takeEvery("*", function* logger(action): any { });
}

function* watchFirstThreeTodosCreation(): any {
  for (let i = 0; i < 3; i++) {
    const action = yield take(addTodo);
    console.log("watchFirstThreeTodosCreation:", action);
  }
  yield put(showTodoCongratulationAction());
}

export default function* rootSaga() {
  yield all([
    helloSaga(),
    sagaSetDataWatcher(),
    watchAndLog(),
    watchFirstThreeTodosCreation(),
  ]);
}

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/style-prop-object */
import { Button, Collapse, CollapseProps, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import PlusIconComponent from '../../assets/icons/PlusIcon';
import PhoneIconComponent from '../../assets/icons/PhoneIcon';
import SubtractIconComponent from '../../assets/icons/SubtractIcon';
import NewsService from '../../services/news';
import MessageSendIcon from '../../assets/icons/MessageSent';
import HomeService from '../../services/home';
import LocationIconComponent from '../../assets/icons/LocationIcon';
import ItemListNewsComponent from '../../components/item-component/item-list-new-component';
import FacebookIconComponent from '../../assets/icons/FacebookIcon';
import TiktokIconComponent from '../../assets/icons/TiktokIcon';
import YoutubeIconComponent from '../../assets/icons/YoutubeIcon';
import MessageIconComponent from '../../assets/icons/MessageIcon';
import ZaloIconComponent from '../../assets/icons/ZaloIcon';

function FooterFormComponent() {
    const [bannerNews, setBannerNews] = useState([]);
    const [faq, setFaq] = useState([]);
    const [dataSocial, setDataSocial] = useState([]);
    const customExpandIcon = ({ isActive }: any) => (
        isActive ? (
            <SubtractIconComponent
                style={'w-4 h-4 fill-colorPrimary'}
            />
        ) : (
            <PlusIconComponent
                style={'w-4 h-4 stroke-colorPrimary'}
            />
        )
    );

    const onChange = (key: string | string[]) => {
        // console.log(key);
    };

    const fetchBannerNews = async () => {
        const { data } = await NewsService.getNewsBanner();

        if (data.message === 'success') {
            const reverseNews = (data.data).reverse();
            setBannerNews(reverseNews);
            // console.log(data.data, 'data banner news')
        }
    };

    const fetchFAQ = async () => {
        const { data } = await HomeService.getAllFaq();

        if (data.message === 'success') {
            setFaq(data.data);
            // console.log(data.data, 'data faq')
        }
    };

    const fetchSocialLink = async () => {
        const response = await HomeService.getLinkSocial("LINK_SOCIAL");

        if (response.response?.data.message === 'success') {
            setDataSocial(response.response?.data.data);
            // console.log(response.response?.data.data, 'data')
        }

    };


    useEffect(() => {
        fetchBannerNews();
        fetchFAQ();
        fetchSocialLink();
    }, []);

    return (
        <footer className='bg-white'>

            <div className='container mx-auto xxxxl:w-full xxs:w-300 px-4 my-10 rounded-3xl'>


                <div className='xs:-mx-4 z-2'>

                    <div className='flex md:flex-row flex-col justify-between shadow-xl overflow-hidden md:rounded-l-3xl rounded-3xl'>
                        <div className='md:w-20 md:py-0 py-3 relative flex items-center justify-center bg-colorSecondary'>
                            <span className=' w-20 md:-rotate-90 text-white text-2xl text-center'>Tin tức</span>
                        </div>
                        <div className='md:w-customWIcon pt-6 pb-5'>
                            <ItemListNewsComponent products={bannerNews} />
                        </div>
                    </div>


                </div>
            </div>

            <div className='container xxxxl:w-full xxs:w-300 px-4 mx-auto'>
                <div className='bg-navMenu font-semibold px-4 py-3 xs:-mx-4 rounded-lg'>CÂU HỎI THƯỜNG GẶP</div>
                <div className='my-8 xs:-mx-4'>
                    <Collapse
                        defaultActiveKey={['1']}
                        onChange={onChange}
                        expandIcon={customExpandIcon}
                        expandIconPosition="end"
                    >
                        {faq.map((item: any) => (
                            <Collapse.Panel
                                key={item.id}
                                header={
                                    <div className="flex items-center font-semibold">
                                        <MessageIconComponent style="mr-2 w-5 h-5 stroke-colorSecondary" />
                                        {item.question}
                                    </div>
                                }
                            >
                                {/* <p>{item.answer}</p> */}
                                <div
                                    className="list-disc list-inside"
                                    dangerouslySetInnerHTML={{ __html: item.answer }}
                                />
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </div>
            </div>
            <hr className='border-dashed border-navMenu' />
            <div className='bg-colorSecondary'>
                <div className='xxs:w-300 xxxxl:w-full container py-8 flex flex-wrap sm:px-0 px-4 justify-center mx-auto '>
                    <div className='lg:w-1/4 sm:w-1/2 w-full'>
                        <div className='relative pb-2 mb-3 '>
                            <div className='uppercase font-bold text-white'>Lê Hiếu Computer</div>
                            <div className='content-[""] bg-colorSecondary h-0.5 w-20 absolute bottom-0 left-0'></div>
                        </div>
                        <div className='flex flex-col items-start'>
                            <div className='flex items-center mb-2.5'>
                                <LocationIconComponent style='w-5 h-5 stroke-white mr-2' />
                                <a
                                    href="https://www.google.com/maps/place/189+Jonam-dong,+Siheung-si,+Gyeonggi-do,+H%C3%A0n+Qu%E1%BB%91c/data=!3m1!4b1!4m6!3m5!1s0x357b64326943244b:0xc2e738c6554910b0!8m2!3d37.382677!4d126.8562403!16s%2Fg%2F11bz8pzjbb?hl=vi&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D" target='_blank'
                                    className='hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>시흥시 조남동 189</a>
                            </div>
                            <div className='flex items-center mb-2.5'>
                                <LocationIconComponent style='w-5 h-5 stroke-white mr-2' />
                                <a
                                    href="https://www.google.com/maps/place/189+Jonam-dong,+Siheung-si,+Gyeonggi-do,+H%C3%A0n+Qu%E1%BB%91c/data=!3m1!4b1!4m6!3m5!1s0x357b64326943244b:0xc2e738c6554910b0!8m2!3d37.382677!4d126.8562403!16s%2Fg%2F11bz8pzjbb?hl=vi&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D" target='_blank'
                                    className='hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>시흥시 정왕동 1417-7</a>
                            </div>
                            <div className='flex items-center mb-2.5'>
                                <PhoneIconComponent style='w-5 h-5 stroke-white mr-2' />
                                <div className='hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>010-6222-1678/010-2357-2678</div>
                            </div>
                            <div className='flex items-center mb-2.5'>
                                <MessageSendIcon style='w-5 h-5 stroke-white mr-2' />
                                <div className='hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Leminhhieu2108@gmail.com</div>
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-1/4 sm:w-1/2 w-full'>
                        <div className='relative pb-2 mb-3'>
                            <div className='uppercase font-bold text-white'>Chính sách cửa hàng</div>
                            <div className='content-[""] bg-colorSecondary h-0.5 w-20 absolute bottom-0 left-0'></div>
                        </div>
                        <div className='flex flex-col items-start'>
                            <a href="" className='mb-2.5 hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Chính sách hoàn tiền</a>
                            <a href="" className='mb-2.5 hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Hướng dẫn mua hàng</a>
                            <a href="" className='mb-2.5 hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Giao hàng vận chuyển</a>
                            <a href="" className='mb-2.5 hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Chính sách bảo hành</a>
                            <a href="" className='mb-2.5 hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Bảo hành phụ kiện</a>
                        </div>
                    </div>
                    <div className='lg:w-1/4 sm:w-1/2 w-full'>
                        <div className='relative pb-2 mb-3'>
                            <div className='uppercase font-bold text-white'>Menu</div>
                            <div className='content-[""] bg-colorSecondary h-0.5 w-20 absolute bottom-0 left-0'></div>
                        </div>
                        <div className='flex flex-col items-start'>
                            <a href="/" className='mb-2.5 hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Trang chủ</a>
                            <a href="/gioi-thieu" className='mb-2.5 hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Giới thiệu</a>
                            <a href="/thong-tin-lien-he" className='mb-2.5 hover:text-colorPrimary duration-300 lg2:text-base text-sm text-white'>Liên hệ</a>
                        </div>
                    </div>
                    <div className='lg:w-1/4 sm:w-1/2 w-full'>
                        <div className='relative pb-2 mb-3'>
                            <div className='uppercase font-bold text-white'>Phản hồi</div>
                            <div className='content-[""] bg-colorSecondary h-0.5 w-20 absolute bottom-0 left-0'></div>
                        </div>
                        <div className='flex flex-col items-start'>
                            <div className='mb-2.5 lg2:text-base text-sm text-white'>Nhận nhiều thông tin ưu đãi cũng như chính sách thông qua đây.</div>
                            <div className='flex items-center justify-between w-full -mx-2'>
                                <div className='w-3/5 px-2'>
                                    <Input placeholder='Email' />
                                </div>
                                <div className='bg-white font-semibold py-1 text-colorSecondary rounded-lg inline-block w-2/5 text-center px-2 lg2:text-base text-sm cursor-pointer'>
                                    Đăng ký
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border-t border-dashed border-navMenu py-2.5'>
                    <div className='container lg2:w-300 xxxxl:w-full flex flex-wrap justify-between items-center mx-auto'>
                        <div className='flex items-center sm:px-0 px-4'>
                            {dataSocial.map((item: any, index: number) => {
                                return (
                                    <>
                                        <a href={`${item.href}`} target='_blank' rel='nofollow' className='w-11 h-11 mr-1 bg-white rounded-full flex items-center justify-center relative bottom-0 transition-all hover:-translate-y-2'>
                                            {item.text === "Facebook" ?
                                                <FacebookIconComponent style='w-6 h-6 fill-colorSecondaryHigher' />
                                                :
                                                item.text === "Tiktok"
                                                    ?
                                                    <TiktokIconComponent style='w-6 h-6' />
                                                    :
                                                    item.text === "Youtube" ?
                                                        <YoutubeIconComponent style='w-6 h-6 fill-colorThird' />
                                                        : <ZaloIconComponent style='w-6 h-6 fill-colorSecondaryHigher' />
                                            }

                                        </a>

                                    </>
                                )
                            })}
                            {/* <a href="" target='_blank' rel='nofollow' className='w-11 h-11 mr-1 bg-white rounded-full flex items-center justify-center relative bottom-0 transition-all hover:-translate-y-2'></a>
                            <a href="" target='_blank' rel='nofollow' className='w-11 h-11 mr-1 bg-white rounded-full flex items-center justify-center relative bottom-0 transition-all  hover:-translate-y-2'></a> */}
                        </div>
                    </div>
                </div>
                <div className=' text-center font-semibold py-4 xs:text-base text-sm text-white sm:px-0 px-4'>
                    Copyright ©2024 Lê Hiếu Computer | Gaming Gear
                </div>
            </div>

        </footer>
    )
}

export default FooterFormComponent;

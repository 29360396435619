/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";
// import axiosPublicInstance from "./axios";

const getProductById = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `/api/AdminProduct/get_by_id?Id=${id}`
    );
    if (response) {
      return {
        response,
      };
    } else {
      return { message: "Product is does not exists." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};



const getCategoryById = async (id: number) => {
    try {
      const response = await axiosInstance.get(
        `/api/AdminProductCategory/get_by_id?id=${id}`
      );
      if (response) {
        return {
          response,
        };
      } else {
        return { message: "Category is does not exists." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };


  const getCollectionById = async (id: number) => {
    try {
      const response = await axiosInstance.get(
        `/api/AdminProductCollection/get_by_id?Id=${id}`
      );
      if (response) {
        return {
          response,
        };
      } else {
        return { message: "Collection is does not exists." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };


const getProductBySlug = async (slug: string) => {
  try {
    const response = await axiosInstance.get(
      `/api/Home/get_product_detail?slug=${slug}`
    );
    if (response) {
      return {
        response,
      };
    } else {
      return { message: "Product is does not exists." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};


const getAllCategory = async () => {
  try {
    const response = await axiosInstance.get(`/api/AdminProductCategory/get_all`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Category is null." };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllCollection = async () => {
  try {
    const response = await axiosInstance.get(`/api/AdminProductCollection/get_all`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Collection is null." };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllProductByHome = async () => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_product_by_home`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

// const updateProduct = async (obj: any) => {
//   try {
//       const response = await axiosInstance.post(`/api/AdminProduct/update`, obj);
//       if (response) {
//           return {
//               response,
//               message: "Update product for news successfully!",
//           };
//       } else {
//           return { message: "Something wrong! Try again" };
//       }
//   } catch (error: any) {
//       throw {
//           error: error.response ? error.response.data : error.message,
//           message: "Internal server error.",
//       };
//   }
// };


const deleteProduct = async (id : any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminProduct/delete`, id);
      if (response) {
          return {
              response,
              message: "Delete product successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};
const deleteCategory = async (id : any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminProductCategory/delete`, id);
      if (response) {
          return {
              response,
              message: "Delete category successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};
const deleteCollection = async (id : any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminProductCollection/delete`, id);
      if (response) {
          return {
              response,
              message: "Delete collection successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};

const updateProduct = async (obj: any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminProduct/update`, obj);
      if (response) {
          return {
              response,
              message: "Update product successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};

const updateCollection = async (obj: any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminProductCollection/update`, obj);
      if (response) {
          return {
              response,
              message: "Update product successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};

const updateCategory = async (obj: any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminProductCategory/update`, obj);
      if (response) {
          return {
              response,
              message: "Update Category successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};

const getAllProductBySearch = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_product_search`, {
      params,
    });

    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const getAllProductByCategory = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/api/Home/get_product_by_category`, {
      params,
    });

    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Have no product" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};


const uploadFile = async (file: File) => {
  try {
    const response = await axiosInstance.post(`/api/FileUpload/upload`, file);

    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Error" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const createCategory = async (object: any) => {
    try {
      const response = await axiosInstance.post(`/api/AdminProductCategory/create`, object);
      if (response) {
        return {
          response,
          message: "Add category successfully",
        };
      } else {
        return { message: "Can not add this category." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };

  const createProduct = async (object: any) => {
    try {
      const response = await axiosInstance.post(`/api/AdminProduct/create`, object);
      if (response) {
        return {
          response,
          message: "Add products successfully",
        };
      } else {
        return { message: "Can not add this category." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };

  const addProductToCollection = async (object: any) => {
    try {
      const response = await axiosInstance.post(`/api/AdminProductCollection/update_products`, object);
      if (response) {
        return {
          response,
          message: "Add products to collection successfully",
        };
      } else {
        return { message: "Can not add this products." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };

  const createCollection = async (object: any) => {
    try {
      const response = await axiosInstance.post(`/api/AdminProductCollection/create`, object);
      if (response) {
        return {
          response,
          message: "Add collection successfully",
        };
      } else {
        return { message: "Can not add this collection." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };

  const getAllProduct= async () => {
    try {
      const response = await axiosInstance.get(`/api/AdminProduct/get_all`);
      if (response.data) {
        return {
          data: response.data,
        };
      } else {
        return { message: "Product is null." };
      }
    } catch (error: any) {
      throw {
        error: error.response ? error.response.data : error.message,
        message: "Internal server error.",
      };
    }
  };

  const getAllProductAttribute= async () => {
    try {
      const response = await axiosInstance.get(`/api/AdminProductAttribute/get_all`);
      if (response.data) {
        return {
          data: response.data,
        };
      } else {
        return { message: "Product attribute is null." };
      }
    } catch (error: any) {
      throw {
        error: error.response ? error.response.data : error.message,
        message: "Internal server error.",
      };
    }
  };

const ProductService = {
  createCollection,
  getProductById,
  getAllCategory,
  getAllProductByHome,
  getAllProductBySearch,
  getProductBySlug,
  getAllProductByCategory,
  createCategory,
  uploadFile,
  getCategoryById,
  getAllProduct,
  createProduct,
  updateProduct,
  deleteProduct,
  updateCategory,
  getAllProductAttribute,
  deleteCategory,
  getAllCollection,
  deleteCollection,
  updateCollection,
  getCollectionById,
  addProductToCollection
};

export default ProductService;

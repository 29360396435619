/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/imgs/lhlogo.png';
import LogoutIconComponent from '../assets/icons/LogoutIcon';
import TabSidebarIconComponent from '../assets/icons/TabSidebarIcon';

type MenuItem = {
    key: string;
    label: string;
    path?: string;
    children?: MenuItem[];
};

const items: MenuItem[] = [
    { key: '1', label: 'Người dùng', path: '/admin/user' },
    { key: '5', label: 'Đơn Hàng', path: '/admin/don-hang' },
    { key: '6', label: 'FAQ', path: '/admin/faq' },
    {
        key: '2',
        label: 'Sản phẩm',
        children: [
            { key: '2-1', label: 'Danh mục sản phẩm', path: '/admin/category' },
            { key: '2-2', label: 'Danh sách sản phẩm', path: '/admin/product' },
        ],
    },
    {
        key: '4',
        label: 'Tin tức',
        children: [
            { key: '4-1', label: 'Danh sách tin tức', path: '/admin/news' },
            { key: '4-2', label: 'Danh mục tin tức', path: '/admin/news-category' },
        ],
    },
    {
        key: '7',
        label: 'Cài đặt',
        children: [
            { key: '7-1', label: 'Tên chương trình khuyến mãi', path: '/admin/text-promotion' },
            { key: '7-2', label: 'Thuộc tính sản phẩm', path: '/admin/product-attributes' },
            { key: '7-3', label: 'Nhà sản xuất', path: '/admin/manufacturer' },
            { key: '7-4', label: 'Đổi mật khẩu', path: '/admin/change-password' },
        ],
    },
    { key: '8', label: 'Banner', path: '/admin/banner' },
    { key: '9', label: 'Bộ sưu tập', path: '/admin/collection' },
    { key: '10', label: 'Danh sách Thành viên', path: '/admin/customer' },
    { key: '11', label: 'Mô tả thanh toán', path: '/admin/bank' },
    { key: '12', label: 'Danh sách Chỉnh sửa Văn bản', path: '/admin/text' },
    { key: '13', label: 'Danh sách mạng xã hội', path: '/admin/social-link' },
];

const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const onMenuClick = (item: MenuItem) => {
        if (item.path) {
            navigate(item.path);
        }
    };

    const selectedKey = items
        .flatMap(item => item.children ? item.children : [item])
        .find(item => location.pathname === item.path)?.key;

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div>
            <div className='relative px-0 w-full mx-auto flex bg-gradient-to-r from-colorLinearF to-colorLinearT'>
                {collapsed === false &&
                    <div className='w-64 inline-block border-r-2 border-gray bg-gradient-to-r from-colorLinearF to-colorLinearT'>
                        <div className='flex justify-center items-center my-5 '>
                            <img src={logo} alt="Lê Hiếu Computer" className='xs:h-15 h-10 rounded-full' />
                        </div>
                        <Menu
                            selectedKeys={selectedKey ? [selectedKey] : undefined}
                            mode="inline"
                            theme="light"
                            className='h-screen !border-none !bg-gradient-to-r from-colorLinearF to-colorLinearT'
                            items={items.map(item => ({
                                key: item.key,
                                label: item.label,
                                children: item.children?.map(subItem => ({
                                    key: subItem.key,
                                    label: subItem.label,
                                    onClick: () => onMenuClick(subItem),
                                })),
                                onClick: item.path ? () => onMenuClick(item) : undefined,
                            }))}
                        />
                    </div>
                }

                <main className='w-full p-5'>
                    <div className='w-full flex justify-between items-center mb-7'>
                        <div className='flex items-center cursor-pointer' onClick={toggleCollapsed}><TabSidebarIconComponent style='w-5 h-5 stroke-colorSecondary' /></div>
                        <div className='flex items-center cursor-pointer' onClick={handleLogout}>
                            <LogoutIconComponent style='w-5 h-5 stroke-colorSecondary mr-2' />
                            <div className=' text-colorSecondary font-semibold'>Đăng xuất !</div>
                        </div>
                    </div>
                    {children}
                </main>
            </div>
        </div>
    );
};

export default AdminLayout;

import { createContext, useCallback, useEffect, useState } from "react";
const TemplateContext = createContext({});

interface IProps {
  children: React.ReactNode;
}

const TemplateProvider = ({ children }: IProps) => {
  const [sharedData, setSharedData] = useState("");

  return (
    <TemplateContext.Provider value={{ sharedData, setSharedData }}>
      {children}
    </TemplateContext.Provider>
  );
};

export { TemplateContext, TemplateProvider };

/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react'
import { Button, Input, notification, Table } from 'antd';
import UserDetailIcon from '../../../../assets/icons/UserDetailIcon';
import OrderService from '../../../../services/orderService';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import EyeIconComponent from '../../../../assets/icons/EyeIcon';
const ManageOrders: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const AdminGetAll = async () => {

        try {
            const result = await OrderService.AdminGetAll();
            // console.log(result.data.data, 'result')
            if (result.data.message === 'success') {
                setData(result.data.data);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    const formatCurrency = (amount: number) => {
        return amount.toLocaleString('ko-KR', { style: 'currency', currency: 'KRW' });
    };

    const columns = [
        {
            title: 'Họ Tên',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                if (status === 'Active') {
                    return <div className='text-success'>Đã xác nhận</div>;
                } else if (status === 'Order') {
                    return <div className='text-colorThird'>Chờ xác nhận</div>;
                } else {
                    return 'Trạng thái không xác định';
                }
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Tổng số tiền',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (text: number) => formatCurrency(text),

        },
        {
            title: '',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link" onClick={() => handleClick(record.id)} >
                        <EyeIconComponent style={"w-6 h-6 fill-colorSecondary"} />
                    </Button>
                </span>
            ),
        },
    ];
    useEffect(() => {
        AdminGetAll();
    }, []);

    const handleClick = (id: number) => {
        navigate(`/admin/don-hang/chi-tiet/${id}`);
    };

    const exportToExcel = () => {
        const filteredData = data.map(({ fullName, phone, email, address, status, note, totalAmount }) => ({
            fullName,
            phone,  // Không cần định dạng lại phone trong trường hợp export xlsx
            email,
            address,
            status,
            note,
            totalAmount
        }));

        // Tạo một worksheet từ dữ liệu JSON
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();

        // Thêm worksheet vào workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

        // Xuất file với tên 'orders.xlsx'
        XLSX.writeFile(workbook, 'orders.xlsx');
    };

    const handleExport = () => {
        exportToExcel();
    };
    const filteredData = data.filter((item: any) =>
        item.phone && item.phone.toString().includes(searchValue)
    );

    return (
        <div>
            <div className='flex justify-end items-center mb-8'>
                <Input
                    placeholder="Tìm kiếm theo số điện thoại"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    style={{ marginRight: 16, width: 300 }}
                />
                <Button type="primary" onClick={handleExport}>
                    Xuất file XLSX
                </Button>
            </div>
            <Table columns={columns} dataSource={filteredData} />
        </div>
    );
};

export default ManageOrders

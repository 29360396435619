import React from 'react';
import { Input, Button, Form, message, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import AuthService from '../../../../services/auth';

const ChangePasswordComponent: React.FC = () => {
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        const { currentPassword, newPassword, confirmPassword } = values;

        if (newPassword !== confirmPassword) {
            message.error('Mật khẩu mới và xác nhận mật khẩu không khớp!');
            return;
        }
        const obj = {
            oldPassWord: currentPassword,
            newPassWord: newPassword
        };

        try {
            const res = await AuthService.ChangePassword(obj);
            // console.log(res, 'result');
            if (res.response?.data.message === 'success') {
                notification.success({
                    message: 'Đổi mật khẩu thành công!',
                    showProgress: true,
                    pauseOnHover: true,
                });
                form.resetFields();
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: res.response?.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Mật khẩu cũ không đúng!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    return (
        <div className='mt-10 p-6'>
            <h2 className='text-2xl font-semibold text-center mb-6'>Đổi mật khẩu</h2>
            <Form
                form={form}
                name="change_password"
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="currentPassword"
                    label="Mật khẩu hiện tại"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu hiện tại!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Mật khẩu hiện tại"
                    />
                </Form.Item>

                <Form.Item
                    name="newPassword"
                    label="Mật khẩu mới"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Mật khẩu mới"
                    />
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    label="Xác nhận mật khẩu mới"
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: 'Vui lòng xác nhận mật khẩu mới!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Mật khẩu không khớp!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Xác nhận mật khẩu mới"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Đổi mật khẩu
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ChangePasswordComponent;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SagaState {
  seconds: number;
}

const initialState: SagaState = {
  seconds: 0,
};

const sagaSlice = createSlice({
  name: "sagaSlice",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<number>) => {
      state.seconds = action.payload;
    },
  },
});

export const { setData } = sagaSlice.actions;
export default sagaSlice.reducer;

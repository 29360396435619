/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, notification, Upload, DatePicker } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import NewsService from '../../../../services/news';
import UploadIconComponent from '../../../../assets/icons/UploadIcon';
import QuillEditorWrapper from '../../../../components/quill-editor/quill-editor.component';
import locale from 'antd/es/date-picker/locale/vi_VN';
import dayjs from 'dayjs';

const { Option } = Select;


const ManageNews = () => {
    const [data, setData] = useState([]);
    const [cate, setCate] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [fileName, setFileName] = useState('');


    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = (record: any) => {
        // console.log(record.id, 'id')
        setIsModalOpen(true);
        setEditingItem(record.id);

        setFileName(record.logo);

        const logoFileList = [
            {
                uid: '-1',
                name: record.logo.split('/').pop(),
                status: 'done',
                url: `https://api.lehieucomputer.com/${record.logo}`,
            }
        ];

        form.setFieldsValue({ ...record, logo: logoFileList, publishedDate: dayjs(record.publishedDate) });
    };

    const handleDelete = async (key: number) => {
        const obj = {
            id: key
        }
        const result = await NewsService.deleteNewsCMS(obj);
        // console.log("obj", obj)
        // console.log(result.response?.data, 'result form')
        if (result.response?.data.message === "success") {
            api.success({
                message: 'Xóa thành công tin tức!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
        fetchNews();
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const publishedDate = new Date(values.publishedDate).setHours(new Date(values.publishedDate).getHours() + 7);
            const formattedDate = new Date(publishedDate).toISOString();

            if (editingItem) {
                // Updating existing news
                const obj = {
                    id: editingItem,
                    title: values.title,
                    author: values.author,
                    categoryID: values.categoryID,
                    isShowBanner: values.isShowBanner,
                    publishedDate: formattedDate,
                    status: values.status,
                    content: values.content,
                    logo: fileName
                };
                const result = await NewsService.updateNewsCMS(obj);

                if (result.response?.data.message === "success") {
                    api.success({
                        message: 'Cập nhật thành công tin tức!',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                } else {
                    api.error({
                        message: 'Có lỗi xảy ra khi cập nhật tin tức!',
                        description: result.response?.data.message || 'Unknown error',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                }
            } else {
                // Creating new news
                const obj = {
                    title: values.title,
                    author: values.author,
                    categoryID: values.categoryID,
                    isShowBanner: values.isShowBanner,
                    status: values.status,
                    publishedDate: formattedDate,
                    content: values.content,
                    logo: fileName
                };
                const result = await NewsService.createNewsCMS(obj);

                if (result.response?.data.message === "success") {
                    api.success({
                        message: 'Tạo thành công tin tức mới!',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                } else {
                    api.error({
                        message: 'Vui lòng điền đầy đủ thông tin!',
                        description: result.response?.data.message || 'Unknown error',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                }
            }

            fetchNews();
            setIsModalOpen(false);
        } catch (error) {

            api.error({
                message: 'Vui lòng điền đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };



    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleUpload = async (options: any) => {

        try {
            const { file, onSuccess } = options;

            const formData = new FormData();
            formData.append('file', file);

            const result = await NewsService.uploadFileNewsCMS(formData);

            if (result.response) {
                onSuccess(result.response.data);
                setFileName(result.response.data.data);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error: any) {
            notification.error({
                message: error.message,
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };



    const fetchNews = async () => {

        try {
            const result = await NewsService.getListNewsCMS();
            // console.log(result.data, 'result')
            if (result.data.message === 'success') {
                setData(result.data.data);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };
    const fetchNewsCategories = async () => {

        try {
            const result = await NewsService.getListNewsCategoryCMS();
            // console.log(result.data, 'result')
            if (result.data.message === 'success') {
                setCate(result.data.data);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
        fetchNews();
        fetchNewsCategories();
    }, []);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const columns = [

        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Tác giả',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Hình ảnh',
            dataIndex: 'logo',
            key: 'logo',
            render: (logo: any) => (
                <img
                    src={`https://api.lehieucomputer.com/${logo}`}
                    alt="Logo"
                    style={{ width: '100px', height: 'auto' }}
                />
            ),
        },
        {
            title: 'Thuộc danh mục',
            dataIndex: 'categoryID',
            key: 'categoryID',
        },
        {
            title: 'Hiện thị tại ảnh bìa',
            dataIndex: 'isShowBanner',
            key: 'isShowBanner',
            render: (isShowBanner: boolean) => (isShowBanner ? 'Có' : 'Không'),
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            key: 'content',
            render: (content: string) => content.trim() === '' ? 'Không' : 'Có',
        },
        {
            title: 'Ngày xuất bản',
            dataIndex: 'publishedDate',
            key: 'publishedDate',
            render: (text: string) => formatDate(text),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                if (status === 'Active') {
                    return <div className='text-success'>Đã xác nhận</div>;
                } else if (status === 'Deactive') {
                    return <div className='text-colorThird'>Chưa xác nhận</div>;
                } else if (status === 'Deleted') {
                    return 'Đã xóa';
                } else {
                    return 'Trạng thái không xác định';
                }
            },
        },
        {
            title: '',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link"
                        onClick={() => handleEdit(record)}
                    >
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];
    const activeCategories = cate.filter((item: any) => item.status === 'Active');
    return (
        <div>
            {contextHolder}
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Thêm tin tức
            </Button>
            <Table columns={columns} dataSource={data} rowKey="id" />

            <Modal
                destroyOnClose={false}
                forceRender={true}
                title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'}
                width={1000}
                open={isModalOpen}
                onOk={handleOk}
                okText={editingItem ? 'Lưu chỉnh sửa' : 'Thêm mới ngay'}
                cancelText="Hủy"
                onCancel={handleCancel}>
                <Form form={form} layout="vertical">
                    <Form.Item name="title" label="Tiêu đề" rules={[{ required: true, message: 'Hãy nhập tiêu đề!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="author" label="Tác giả" rules={[{ required: true, message: 'Hãy nhập tác giả!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="isShowBanner"
                        label="Hiện thị tại ảnh bìa"
                        rules={[{ required: true, message: 'Hãy chọn trạng thái hiển thị!' }]}
                    >
                        <Select placeholder="Chọn trạng thái hiện thị">
                            <Option value={true}>Có</Option>
                            <Option value={false}>Không</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="categoryID"
                        label="Thuộc danh mục"
                        rules={[{ required: true, message: 'Hãy chọn danh mục!' }]}
                    >
                        <Select placeholder="Chọn danh mục">
                            {activeCategories.map((item: any) => (
                                <Option key={item.id} value={item.id}>
                                    {item.categoryName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="logo"
                        label="Hình ảnh"
                        rules={[{ required: true, message: 'Hãy nhập hình ảnh!' }]}
                        valuePropName="fileList"
                        getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList}
                    >
                        <Upload
                            name="logo"
                            listType="picture"
                            customRequest={handleUpload}
                            maxCount={1}
                        >
                            <Button icon={<UploadIconComponent />}>Chọn tệp</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="publishedDate"
                        label="Ngày xuất bản"
                        rules={[{ required: true, message: 'Hãy chọn ngày xuất bản!' }]}
                    >
                        <DatePicker
                            showTime
                            // format="DD/MM/YYYY HH:mm"
                            placeholder="Chọn ngày và giờ xuất bản"
                            locale={locale}
                            style={{ width: '100%' }}
                        />
                        {/* <Input type='date' placeholder="Chọn ngày và giờ xuất bản" /> */}
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Trạng thái"
                        rules={[{ required: true, message: 'Hãy chọn trạng thái!' }]}
                    >
                        <Select placeholder="Chọn trạng thái">
                            <Option value="Active">Đã xác nhận</Option>
                            <Option value="Deactivate">Chưa xác nhận</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="content"
                        label="Nội dung"
                        rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
                    >
                        <QuillEditorWrapper
                        />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default ManageNews;

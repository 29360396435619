/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, Switch, Upload, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import ProductService from '../../../../services/product';
import NewsService from '../../../../services/news';
import { env } from 'process';
import BankService from '../../../../services/bank';
import QuillEditorWrapper from '../../../../components/quill-editor/quill-editor.component';

interface Item {
    key: string;
    id:number;
    categoryName: string;
    categoryNameSlug: string;
    parentId: number;
    logo: File;
    status: string;
    subcategory:string;
    isShowHome: boolean;
    isShowMenu: boolean;
}

const ManageItemsBank
: React.FC<{ title: string }> = ({ title }) => {
    // const [data, setData] = useState<Item[]>([]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<Item | null>(null);
    const [form] = Form.useForm();

    const [listBank, setListBank] = useState<Item[]>([]);

    const [bankById, setBankById] = useState<Item>();

    const getAllBank = async () => {
        const result = await BankService.getAllBank();
    
        if (result.data.message === 'success') {
        const formattedData = await Promise.all(
            result.data.data.map(async (bank: any, index: number) => {
                return {
                    key: bank.id || index.toString(),
                    bankName: bank.bankName,
                    bankNumber: bank.bankNumber,
                    bankAccount: bank.bankAccount,
                    description: bank.description,
                    status: bank.status,
                };
            })
        );
          setListBank(formattedData);
        }
      };


      const getBankById = async (id: number) => {
        const result = await BankService.getBankById(id);
        if (result.response?.data.message === 'success') {
          setBankById(result.response?.data.data);
        }
      };

      useEffect(() => {
        getAllBank();
      }, []);

      const [api, contextHolder] = notification.useNotification();

    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = async (record: any) => {
        const result = await BankService.getBankById(record.key);
        setEditingItem(result.response?.data.data);
        setIsModalOpen(true);
        form.setFieldsValue({...result.response?.data.data});
    };

    const handleDelete = async (key: number) => {
        const obj = {
            id: key
        }
        const result = await BankService.deleteBank(obj);
        if (result.response?.data.message === "success") {
            api.success({
                message: 'Xóa danh mục thành công',
                showProgress: true,
                pauseOnHover: true,
            }); 
            window.location.reload();
        }
    };

    const [addCategory, setAddCategory] = useState()

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
                if (editingItem) {
                    Object.assign(values, {
                        ...values,
                        id:editingItem.id,
                        bankName:values.bankName || "",
                        bankNumber: values.bankNumber || "",
                        bankAccount: values.bankAccount || "",
                        description: values.description,
                        status: values.status,
                    });
                    const result = await BankService.updateBank(values);
    
                        if (result.response?.data.message === "success") {
                            api.success({
                                message: 'Update tài khoản ngân hàng thành công',
                                showProgress: true,
                                pauseOnHover: true,
                              }); 
                              window.location.reload();
                        }
                } else {
                    const newValues = {
                        ...values,
                        bankName : values.bankName || "",
                        bankNumber: values.bankNumber || "",
                        bankAccount: values.bankAccount|| "",
                        description: values.description,
                        status:values.status || "",
                    };
                
                                const result = await BankService.createBank(newValues);
                                if (result.response) {
                                    setAddCategory(result.response.data.data);
                                    api.success({
                                        message: 'Thêm tài khoản ngân hàng thành công',
                                        showProgress: true,
                                        pauseOnHover: true,
                                    }); 
                                    window.location.reload();
                                }
    
                    setIsModalOpen(false);
                }
            
        } catch (error) {
            api.error({
                message: 'Vui lòng điền đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        // {
        //     title: 'Tên ngân hàng',
        //     dataIndex: 'bankName',
        //     key: 'bankName',
        // },
        // {
        //     title: 'Số tài khoản',
        //     dataIndex: 'bankNumber',
        //     key: 'bankNumber',
        // },
        // {
        //     title: 'Chủ tài khoản',
        //     dataIndex: 'bankAccount',
        //     key: 'bankAccount',
        // },
        {
            title: 'Mô tả thanh toán',
            dataIndex: 'description',
            key: 'description',
            render:(description: string) =>(
                <div dangerouslySetInnerHTML={{ __html: description || '' }} />
            ),
        },

        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status : any) => (
                <span style={{ color: status === 'Active' ? 'green' : 'red', fontWeight: 'bold' }}>
                    {status === 'Active' ? 'Hoạt động' : 'Không hoạt động'}
                </span>
            ),
        },
        {
            title: 'Sửa/Xóa',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link" onClick={() => handleEdit(record)}>
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record?.key)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const { Option } = Select;

    return (
        <div>
            {contextHolder}
            {/* <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Thêm mô tả ngân hàng
            </Button> */}
            <Table columns={columns} dataSource={listBank} />

            <Modal destroyOnClose={false} forceRender={true} title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
                <Form form={form} layout="vertical">
                    {/* <Form.Item name="bankName" label="Tên ngân hàng" rules={[{ required: true, message: 'Hãy nhập tên ngân hàng!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="bankNumber" label="Số tài khoản" rules={[{ required: true, message: 'Hãy nhập số tài khoản!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="bankAccount" label="Chủ tài khoản" rules={[{ required: true, message: 'Hãy nhập chủ tài khoản!' }]}>
                        <Input />
                    </Form.Item> */}
                    <Form.Item name="description" label="Mô tả thanh toán" rules={[{ required: false }]}>
                            <QuillEditorWrapper />
                    </Form.Item>
                    <Form.Item name="status" label="Trạng thái" rules={[{ required: true, message: 'Hãy nhập trạng thái!' }]}>
                                <Select placeholder="Chọn trạng thái">
                                    <Option value="Active">Active</Option>
                                    <Option value="Deactivate">Deactivate</Option>
                                </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageItemsBank
;

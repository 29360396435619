/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";

const Login = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AppUser/login`, obj);
        if (response) {
            return {
                response,
                message: "Login successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const Register = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AppUser/register`, obj);
        if (response) {
            return {
                response,
                message: "Register successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const ChangePassword = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AppUser/changePassword`, obj);
        if (response) {
            return {
                response,
                message: "Change Password successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const AuthService = {
    Login,
    ChangePassword,
    Register
};

export default AuthService;
/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";

const getNewsBanner = async () => {
    try {
        const response = await axiosInstance.get(`/api/News/get_news_banner`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const getNewsCategory = async () => {
    try {
        const response = await axiosInstance.get(`/api/News/get_categories`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};
const getListNews = async () => {
    try {
        const response = await axiosInstance.get(`/api/News/get_news`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const getNewsBySlug = async (slug: any) => {
    try {
        const response = await axiosInstance.get(`/api/News/get_news_by_category?slug=${slug}`);

        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no product" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const getDetailNewsBySlug = async (slug: any) => {
    try {
        const response = await axiosInstance.get(`/api/News/get_news_detail?slug=${slug}`);

        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no detail" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const getListNewsCategoryCMS = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminNewsCategory/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const createNewsCategoryCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminNewsCategory/create`, obj);
        if (response) {
            return {
                response,
                message: "Create category for news successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const getNewsCategoryCMSById = async (id: any) => {
    try {
        const response = await axiosInstance.get(`/api/AdminNewsCategory/get_by_id?Id=${id}`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const updateNewsCategoryCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminNewsCategory/update`, obj);
        if (response) {
            return {
                response,
                message: "Update category for news successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const deleteNewsCategoryCMS = async (id: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminNewsCategory/delete`, id);
        if (response) {
            return {
                response,
                message: "Delete category successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const getListNewsCMS = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminNews/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const uploadFileNewsCMS = async (formData: any) => {
    try {
        const response = await axiosInstance.post(`/api/FileUpload/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (response) {
            return {
                response,
                message: "Tải lên thành công!",
            };
        } else {
            return { message: "Upload failed" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Tải lên thất bại!",
        };
    }
};

const createNewsCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminNews/create`, obj);
        if (response) {
            return {
                response,
                message: "Create news successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const updateNewsCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminNews/update`, obj);
        if (response) {
            return {
                response,
                message: "Update news successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const deleteNewsCMS = async (id: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminNews/delete`, id);
        if (response) {
            return {
                response,
                message: "Delete news successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};


const NewsService = {
    getNewsBanner,
    getNewsCategory,
    getListNews,
    getNewsBySlug,
    getListNewsCategoryCMS,
    createNewsCategoryCMS,
    getNewsCategoryCMSById,
    updateNewsCategoryCMS,
    deleteNewsCategoryCMS,
    getListNewsCMS,
    uploadFileNewsCMS,
    createNewsCMS,
    updateNewsCMS,
    deleteNewsCMS,
    getDetailNewsBySlug
};

export default NewsService;

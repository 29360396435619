/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Pagination, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import ClockIconComponent from '../../assets/icons/ClockIcon'
import MessageIconComponent from '../../assets/icons/MessageIcon'
import EyeIconComponent from '../../assets/icons/EyeIcon'
import ArrowDownIconComponent from '../../assets/icons/ArrowDownIcon'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import NewsService from '../../services/news'

function NewsTechnologyDetailPage() {
    const { slugNews } = useParams();
    const location = useLocation();
    const [placeholder, setPlaceholder] = useState('Chọn mục');
    const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
    const [cateNews, setCateNews] = useState([]);
    const [cateNewsMore, setCateNewsMore] = useState([]);
    const [cateId, setCateId] = useState();
    const [id, setId] = useState();
    const [cateParentSlug, setCateParentSlug] = useState('');
    const [cateParentName, setCateParentName] = useState('');
    const [detailNews, setDetailNews] = useState<any>(null);
    const [formattedDate, setFormattedDate] = useState('');
    const navigate = useNavigate();

    const handleChange = (value: string) => {
        setSelectedValue(value);
        window.location.href = `/tin-tuc/${value}`;
    };

    const fetchDetailNews = async (slug: any) => {
        const { data } = await NewsService.getDetailNewsBySlug(slug);
        if (data.message === 'success') {
            setDetailNews(data.data);
            setCateId(data.data.categoryID);
            setId(data.data.id);
            const date = new Date(data.data.publishedDate);

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = date.getHours() % 12 || 12;
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const ampm = date.getHours() >= 12 ? 'pm' : 'am';
            const formattedDate = `${month}-${day}-${year}, ${hours}:${minutes} ${ampm}`;
            setFormattedDate(formattedDate);
            // console.log(data.data, 'data cate news')

        }

    };

    const fetchNewsByCategory = async (slug: any) => {
        const { data } = await NewsService.getNewsBySlug(slug);

        if (data.message === 'success') {
            const newsArray = data.data;
            const filteredItems: any = newsArray.filter((item: any) => item.id !== id);
            setCateNewsMore(filteredItems);
            // console.log(filteredItems, 'data cate news')
        }
    };

    const fetchCategoryNews = async () => {
        const { data } = await NewsService.getNewsCategory();

        if (data.message === 'success') {
            setCateNews(data.data);
            // console.log(data.data, 'data cate news')
        }
    };
    useEffect(() => {
        fetchCategoryNews();
        fetchDetailNews(slugNews);
    }, []);

    useEffect(() => {
        if (cateId && cateNews.length > 0) {
            const foundCategory: any = cateNews.find((cate: any) => cate.id === cateId);
            if (foundCategory) {
                fetchNewsByCategory(foundCategory.slug);
                setCateParentSlug(foundCategory.slug);
                setCateParentName(foundCategory.categoryName)
            }
        }
    }, [cateId, cateNews]);

    return (
        <div>
            <div className='container mx-auto sm:px-0 px-4 xxs:w-300 xxxxl:w-full'>
                <div className='py-3.5'>
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                title: <div><a href="/" className="hover:!text-colorPrimary hover:!bg-white">Trang chủ</a></div>,
                            },
                            {
                                title: <div><a href="/tin-tuc" className="hover:!text-colorPrimary hover:!bg-white">Tin tức</a></div>,
                            },
                            {
                                title: <div><a href={`/tin-tuc/${cateParentSlug}`} className="hover:!text-colorPrimary hover:!bg-white">{cateParentName}</a></div>,
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='container m-auto sm:px-0 px-4 xxs:w-300 xxxxl:w-full'>
                <div className='md:block hidden'>
                    {cateNews.map((cate: any) => {
                        // console.log(cate.slug, 'cate', slug)
                        return (
                            <a href={`/tin-tuc/${cate.slug}`}
                                className={`text-lg uppercase font-semibold inline-block mr-8 mb-5 cursor-pointer ${cateId === cate.id ? 'text-colorSecondary' : ''}`}
                            >{cate.categoryName}</a>
                        )
                    })}
                </div>
                <div className='py-3.5 w-full md:hidden block'>
                    <Select
                        className='w-full'
                        showSearch
                        onChange={handleChange}
                        placeholder={placeholder}
                        value={selectedValue}
                        optionFilterProp="label"
                        suffixIcon={<ArrowDownIconComponent style='w-4 h-4 fill-black/50' />}
                        filterSort={(optionA, optionB) =>
                            String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                        }
                        options={cateNews.map((cate: any) => ({
                            value: cate.slug,
                            label: cate.categoryName,
                        }))}
                    />
                </div>
                <div className='py-2.5 px-5 bg-white md:rounded-lg shadow-lg'>
                    <div className='mb-2.5 md:text-2xl text-xl font-semibold'>{detailNews !== null && detailNews.title}</div>
                    <div className='flex items-center border-b border-b-black/10 pb-3.5 mb-4'>
                        <div className='flex items-center sm:mr-6 mr-2'>
                            <ClockIconComponent style='w-4 h-4 stroke-black/50 mr-1' />
                            <div className='text-black/50 md:text-sm text-xs'>{formattedDate}</div>
                        </div>
                        <div className='flex items-center sm:mr-6 mr-2'>
                            <MessageIconComponent style='w-4 h-4 stroke-black/50 mr-1' />
                            <div className='text-black/50 md:text-sm text-xs'>0</div>
                        </div>
                        <div className='flex items-center sm:mr-6 mr-2'>
                            <EyeIconComponent style='w-4 h-4 stroke-black/50 mr-1' />
                            <div className='text-black/50 md:text-sm text-xs'>2227</div>
                        </div>
                    </div>
                    <div>
                        {detailNews !== null && (
                            <div
                                dangerouslySetInnerHTML={{ __html: detailNews.content }}
                            />
                        )}
                    </div>
                    {cateNewsMore && cateNewsMore.length > 0 &&
                        <div className='border-t border-t-black/10 pt-3.5 mt-3.5 pb-8'>
                            <div className='uppercase mb-2.5 font-semibold md:text-2xl text-xl'>tin liên quan</div>
                            <div className='md:-mx-2 flex flex-wrap'>
                                {cateNewsMore.map((itemNews: any, index) => {
                                    const date = new Date(itemNews.publishedDate);

                                    const day = String(date.getDate()).padStart(2, '0');
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const year = date.getFullYear();
                                    const hours = date.getHours() % 12 || 12;
                                    const minutes = String(date.getMinutes()).padStart(2, '0');
                                    const ampm = date.getHours() >= 12 ? 'pm' : 'am';
                                    const formattedDate = `${month}-${day}-${year}, ${hours}:${minutes} ${ampm}`;
                                    return (
                                        <a key={index} href={`/tin-tuc-chi-tiet/${itemNews.slug}`} className='lg2:w-1/4 md:w-1/3 sm:w-1/2 w-full md:px-2 mb-4 pb-2 md:border-none border-b border-b-black/20 '>
                                            <div className='block relative mb-3.5 overflow-hidden lg:h-72 h-64 group'>
                                                <img src={`https://api.lehieucomputer.com${itemNews.logo}`} alt=""
                                                    className='block transition-transform duration-300 ease-in-out transform group-hover:scale-110 max-w-full w-full object-cover lg:h-72 h-64' />
                                            </div>
                                            <div>
                                                <div className='mb-2.5'>
                                                    <div className='block md:text-base text-sm font-semibold hover:text-colorThird duration-300'>
                                                        {itemNews.title}
                                                    </div>
                                                </div>
                                                <div className='flex items-center mr-6'>
                                                    <ClockIconComponent style='w-4 h-4 stroke-black/50 mr-1' />
                                                    <div className='text-black/50 text-sm'>{formattedDate}</div>
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })}

                            </div>
                        </div>
                    }


                </div>

            </div>
        </div>
    )
}

export default NewsTechnologyDetailPage

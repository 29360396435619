import React from 'react'
interface IConProps {
    style?: string;
    stroke?: string;
}

const SubtractIconComponent: React.FC<IConProps> = ({ style }) => {
    return (
        <svg className={style} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>subtract</title>
            <path d="M24 14v4h-16v-4h16z"></path>
        </svg>
    )
}

export default SubtractIconComponent
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ClientService } from '../services';
import { RootState } from '../store';

export interface Client {
    id: number;
    username: string;
    fullName: string;
    email: string;
    phone: string;
    avatar: string;

}

export interface ClientState {
    clientList: Client[];
    client: Client | null;
    loading: boolean;
    error: string | null;
}

const initialState: ClientState = {
    clientList: [],
    client: null,
    loading: false,
    error: null,
};

export const fetchListClient = createAsyncThunk<Client[]>(
    'client/fetchListClient',
    async () => {
        const response = await ClientService.getClient({ pageIndex: 1, pageSize: 10 });
        return response.response?.data.data;
    }
);

export const getClientById = createAsyncThunk<Client, number>(
    'client/getClientById',
    async (clientId) => {
        const response = await ClientService.getClientById(clientId);
        return response.response?.data.data;
    }
);

export const fetchListClientNewest = createAsyncThunk<Client[]>(
    'client/fetchListClientNewest',
    async () => {
        const response = await ClientService.getListNewestClient({ pageIndex: 0, pageSize: 10 });
        // console.log(response.response?.data.data, "res client")
        return response.response?.data.data.data;
    }
);

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchListClient.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchListClient.fulfilled, (state, action: PayloadAction<Client[]>) => {
                state.loading = false;
                state.clientList = action.payload;
            })
            .addCase(fetchListClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch clients';
            })
            .addCase(getClientById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getClientById.fulfilled, (state, action: PayloadAction<Client>) => {
                state.loading = false;
                state.client = action.payload;
                const clientIndex = state.clientList.findIndex(client => client.id === action.payload.id);
                if (clientIndex >= 0) {
                    state.clientList[clientIndex] = action.payload;
                } else {
                    state.clientList.push(action.payload);
                }
            })
            .addCase(getClientById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch client';
            })
            .addCase(fetchListClientNewest.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchListClientNewest.fulfilled, (state, action: PayloadAction<Client[]>) => {
                state.loading = false;
                state.clientList = action.payload;
            })
            .addCase(fetchListClientNewest.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch clients';
            })
    },
});

export default clientSlice.reducer;

/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";



const getAllProductCollectionCMS = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminProductCollection/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Product Collection is null." };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const createProductCollectionCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminProductCollection/create`, obj);
        if (response) {
            return {
                response,
                message: "Create Product Collection successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const updateProductCollectionCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminProductCollection/update`, obj);
        if (response) {
            return {
                response,
                message: "Update Product Collection successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const deleteProductCollectionCMS = async (id: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminProductCollection/delete`, id);
        if (response) {
            return {
                response,
                message: "Delete Product Collection successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const getAllProductCollectionHome = async () => {
    try {
        const response = await axiosInstance.get(`/api/ProductCollection/get_product_collection_by_home`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Product Collection is null." };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const getProductCollectionBySlug = async (slug: any) => {
    try {
        const response = await axiosInstance.get(`/api/ProductCollection/get_by_slug?slug=${slug}`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Product Collection is null." };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};



const ProductCollectionService = {
    getAllProductCollectionHome,
    getProductCollectionBySlug
};

export default ProductCollectionService;

import React from 'react'

interface IConProps {
    style?: string;
}

const CPUIconComponent: React.FC<IConProps> = ({ style }) => {
    return (
        <svg className={style} viewBox="0 0 64 64" data-name="Material Expand" id="Material_Expand" xmlns="http://www.w3.org/2000/svg"><path d="M60,34H34V30.5A6.508,6.508,0,0,0,27.5,24h-13a2.5,2.5,0,0,1,0-5h33a6.5,6.5,0,0,0,0-13H24v4H47.5a2.5,2.5,0,0,1,0,5h-33a6.5,6.5,0,0,0,0,13h13A2.5,2.5,0,0,1,30,30.5V34H4a2,2,0,0,0-2,2V60a2,2,0,0,0,2,2H60a2,2,0,0,0,2-2V36A2,2,0,0,0,60,34ZM58,58H6V38H58Z" /><rect height="4" width="4" x="10" y="50" /><rect height="4" width="4" x="10" y="42" /><rect height="4" width="4" x="18" y="42" /><rect height="4" width="4" x="26" y="42" /><rect height="4" width="4" x="34" y="42" /><rect height="4" width="4" x="42" y="42" /><rect height="4" width="4" x="50" y="42" /><rect height="4" width="4" x="18" y="50" /><rect height="4" width="4" x="42" y="50" /><rect height="4" width="4" x="50" y="50" /><rect height="4" width="12" x="26" y="50" /><rect height="4" width="4" x="16" y="6" /></svg>
    )
}

export default CPUIconComponent
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import ClockIconComponent from '../../assets/icons/ClockIcon';
import MessageIconComponent from '../../assets/icons/MessageIcon';
import EyeIconComponent from '../../assets/icons/EyeIcon';
import ArrowDownIconComponent from '../../assets/icons/ArrowDownIcon';
import { useLocation } from 'react-router-dom';
import NewsService from '../../services/news';

function NewsPage() {
    const location = useLocation();
    const [placeholder, setPlaceholder] = useState('Chọn mục');
    const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
    const [cateNews, setCateNews] = useState([]);
    const [bannerNews, setBannerNews] = useState([]);
    const [listNewsBySlug, setListNewsBySlug] = useState([]);
    const [listNews, setListNews] = useState([]);

    const handleChange = (value: string) => {
        setSelectedValue(value);
        window.location.href = `/tin-tuc/${value}`;
    };

    const fetchCategoryNews = async () => {
        const { data } = await NewsService.getNewsCategory();

        if (data.message === 'success') {
            setCateNews(data.data);
            // console.log(data.data, 'data cate news')
        }
    };
    const fetchBannerNews = async () => {
        const { data } = await NewsService.getNewsBanner();

        if (data.message === 'success') {
            setBannerNews(data.data);
            // console.log(data.data, 'data banner news')
        }
    };

    // const fetchNewsBySlug = async () => {
    //     const { data } = await NewsService.getNewsBySlug('tin-cong-nghe');

    //     if (data.message === 'success') {
    //         setListNewsBySlug(data.data);
    //         console.log(data.data, 'data slugs news')
    //     }
    // };

    const fetchListNews = async () => {
        const { data } = await NewsService.getListNews();

        if (data.message === 'success') {
            setListNews(data.data);
            // console.log(data.data, 'data list news')
        }
    };

    useEffect(() => {
        fetchCategoryNews();
        fetchBannerNews();
        // fetchNewsBySlug();
        fetchListNews();
    }, []);


    return (
        <div>
            <div className='container mx-auto sm:px-0 px-4 xxs:w-300 xxxxl:w-full'>
                <div className='py-3.5  '>
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                title: <a href="/" className="hover:!text-colorPrimary hover:!bg-white">Trang chủ</a>,
                            },
                            {
                                title: <a href="/tin-tuc" className="hover:!text-colorPrimary hover:!bg-white">Tin tức</a>,
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='container mx-auto sm:px-0 px-4 xxs:w-300 xxxxl:w-full'>
                <div className='md:block hidden'>
                    {cateNews.map((cate: any) => {
                        return (
                            <a href={`/tin-tuc/${cate.slug}`} className='text-lg uppercase font-semibold inline-block mr-8 mb-5 cursor-pointer'>{cate.categoryName}</a>
                        )
                    })}
                </div>
                <div className='py-3.5 w-full md:hidden block'>
                    <Select
                        className='w-full'
                        showSearch
                        onChange={handleChange}
                        placeholder={placeholder}
                        value={selectedValue}
                        optionFilterProp="label"
                        suffixIcon={<ArrowDownIconComponent style='w-4 h-4 fill-black/50' />}
                        filterSort={(optionA, optionB) =>
                            String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                        }
                        options={cateNews.map((cate: any) => ({
                            value: cate.slug,
                            label: cate.categoryName,
                        }))}
                    />
                </div>
                {bannerNews &&
                    <div className='flex flex-wrap md:p-8 p-4 mb-5 bg-white md:rounded-lg shadow-lg'>

                        {bannerNews.slice(0, 1).map((banner: any) => {
                            const date = new Date(banner.publishedDate);

                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            const hours = date.getHours() % 12 || 12;
                            const minutes = String(date.getMinutes()).padStart(2, '0');
                            const ampm = date.getHours() >= 12 ? 'pm' : 'am';
                            const formattedDate = `${month}-${day}-${year}, ${hours}:${minutes} ${ampm}`;
                            return (
                                <div className='w-full mb-5 relative'>
                                    <a href={`/tin-tuc-chi-tiet/${banner.slug}`} className='block relative mb-3.5 overflow-hidden cursor-pointer group'>
                                        <img src={`https://api.lehieucomputer.com${banner.logo}`} alt=""
                                            className='block m-auto transition-transform duration-300 ease-in-out transform group-hover:scale-110 max-w-full w-full object-cover h-auto' />
                                        <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-black md:py-9 py-3 md:px-4 px-2 '>
                                            <div className='md:mb-4 mb-2'>
                                                <div className='text-white md:text-2xl text-sm font-semibold'>{banner.title}</div>
                                            </div>
                                            <div className='flex items-center'>
                                                <div className='flex items-center mr-6'>
                                                    <ClockIconComponent style='md:w-4 md:h-4 w-3.5 h-3.5 stroke-white mr-1' />
                                                    <div className='text-white md:text-sm text-xs'>{formattedDate}</div>
                                                </div>
                                                <div className='flex items-center mr-6'>
                                                    <MessageIconComponent style='md:w-4 md:h-4 w-3.5 h-3.5 stroke-white mr-1' />
                                                    <div className='text-white md:text-sm text-xs'>0</div>
                                                </div>
                                                <div className='flex items-center mr-6'>
                                                    <EyeIconComponent style='md:w-4 md:h-4 w-3.5 h-3.5 stroke-white mr-1' />
                                                    <div className='text-white md:text-sm text-xs'>2227</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                        <div className='sm:-mx-2 flex w-full flex-wrap overflow-hidden'>
                            {bannerNews.slice(1).map((newsItem: any) => {
                                const date = new Date(newsItem.publishedDate);

                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                const hours = date.getHours() % 12 || 12;
                                const minutes = String(date.getMinutes()).padStart(2, '0');
                                const ampm = date.getHours() >= 12 ? 'pm' : 'am';
                                const formattedDate = `${month}-${day}-${year}, ${hours}:${minutes} ${ampm}`;
                                return (

                                    <div className='xl:w-1/4 lg:w-1/3 sm:w-1/2 w-full sm:px-2 group mb-4'>
                                        <a href={`/tin-tuc-chi-tiet/${newsItem.slug}`} className='block relative mb-3.5 overflow-hidden '>
                                            <img src={`https://api.lehieucomputer.com${newsItem.logo}`} alt=""
                                                className='block m-auto transition-transform duration-300 ease-in-out transform group-hover:scale-110 max-w-full w-full sm:h-72 h-40 object-cover' />
                                        </a>
                                        <div>
                                            <div className='mb-2.5'>
                                                <a href={`/tin-tuc-chi-tiet/${newsItem.slug}`} className='block font-semibold md:text-base text-xs hover:text-colorThird duration-300'>
                                                    {newsItem.title}
                                                </a>
                                            </div>
                                            <div className='flex items-center'>
                                                <ClockIconComponent style='md:w-4 md:h-4 w-3.5 h-3.5 stroke-black/50 mr-1' />
                                                <div className='text-black/50 md:text-sm text-xs'>{formattedDate}</div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                    </div>
                }

                {listNews.map((news: any) => {
                    return (
                        <div>
                            {news.news.length > 0 &&
                                <div className='bg-white md:rounded-lg shadow-lg py-2.5 px-5 mt-5'>
                                    <div className='mb-5 pb-2.5 border-b border-b-gray relative'>
                                        <a href={`/tin-tuc/${news.slug}`} className='hover:text-colorThird font-bold uppercase md:text-xl text-base'>
                                            {news.categoryName}
                                        </a>
                                        <div className='content-[""] w-8 h-px float-left bg-colorSecondaryHigher absolute left-0 -bottom-px'></div>
                                    </div>
                                    {
                                        news.news.length > 0 && news.news.map((newsItem: any) => {
                                            const date = new Date(newsItem.publishedDate);

                                            const day = String(date.getDate()).padStart(2, '0');
                                            const month = String(date.getMonth() + 1).padStart(2, '0');
                                            const year = date.getFullYear();
                                            const hours = date.getHours() % 12 || 12;
                                            const minutes = String(date.getMinutes()).padStart(2, '0');
                                            const ampm = date.getHours() >= 12 ? 'pm' : 'am';
                                            const formattedDate = `${month}-${day}-${year}, ${hours}:${minutes} ${ampm}`;
                                            return (
                                                <>

                                                    <div>
                                                        <div className='mb-5 flex items-start'>
                                                            <a href={`/tin-tuc-chi-tiet/${newsItem.slug}`} className='block sm:mr-4 mr-2 md:w-75 w-2/5'>
                                                                <img src={`https://api.lehieucomputer.com${newsItem.logo}`} alt="" className='max-w-full w-full object-cover sm:h-48 xs:h-36 h-28' />
                                                            </a>
                                                            <div className='w-textForNews'>
                                                                <div className='mb-2.5'>
                                                                    <a href={`/tin-tuc-chi-tiet/${newsItem.slug}`} className='hover:text-colorThird font-semibold duration-300 md:text-base text-sm line-clamp-2'>
                                                                        {newsItem.title}
                                                                    </a>
                                                                </div>
                                                                <div className='flex flex-wrap items-center mb-2'>
                                                                    {/* <div></div> */}
                                                                    <div className='capitalize mr-1 text-sm'>by</div>
                                                                    <div className='font-semibold sm:mr-3 text-colorSecondaryHigher text-sm'>{newsItem.author}</div>
                                                                    <div className='flex items-center flex-wrap'>
                                                                        <div className='flex items-start mr-6 md:my-0 my-2'>
                                                                            <ClockIconComponent style='w-4 h-4 stroke-black/50 mr-1' />
                                                                            <div className='text-black/50 md:text-sm text-xs'>{formattedDate}</div>
                                                                        </div>
                                                                        <div className='flex items-start mr-6'>
                                                                            <MessageIconComponent style='w-4 h-4 stroke-black/50 mr-1' />
                                                                            <div className='text-black/50 md:text-sm text-xs'>0</div>
                                                                        </div>
                                                                        <div className='flex items-start mr-6'>
                                                                            <EyeIconComponent style='w-4 h-4 stroke-black/50 mr-1' />
                                                                            <div className='text-black/50 md:text-sm text-xs'>2227</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='sm:text-sm text-xs overflow-hidden text-ellipsis md:line-clamp-none line-clamp-2'>
                                                                    {newsItem.title}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            )
                                        })
                                    }
                                    <div className='relative flex justify-center my-5'>
                                        <div className='content-[""] md:w-readmoreNewsW w-readmoreNewsWMB h-px float-left bg-black/10 absolute top-1/2 left-0'></div>
                                        <a href={`/tin-tuc/${news.slug}`} className=' md:leading-10 leading-9 md:text-base text-sm md:w-64 md:px-0 px-10 float-left border border-black/10 uppercase text-center duration-500 hover:text-white hover:bg-colorSecondaryHigher'>
                                            Xem thêm
                                        </a>
                                        <div className='content-[""] md:w-readmoreNewsW w-readmoreNewsWMB h-px float-left bg-black/10 absolute top-1/2 right-0 block clear-both'></div>
                                    </div>
                                </div>}
                        </div>



                    )
                })}

            </div>
        </div>
    )
}

export default NewsPage;

/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import CustomerCMSService from '../../../../services/customer';
import * as XLSX from 'xlsx';

const { Option } = Select;

const ManageCustomerForm = () => {
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = (record: any) => {
        // console.log(record.id, 'id')
        setIsModalOpen(true);
        setEditingItem(record.id);
        form.setFieldsValue(record);
    };

    const handleDelete = async (key: number) => {
        const obj = {
            id: key
        }
        const result = await CustomerCMSService.deleteCustomerCMS(obj);
        // console.log(result.response?.data, 'result form')
        if (result.response?.data.message === "success") {
            api.success({
                message: 'Xóa thành công người dùng!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
        fetchCustomerCMS();
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();

            let result;

            if (editingItem) {
                const obj = {
                    id: editingItem,
                    email: values.email,
                    phone: values.phone,
                    fullName: values.fullName,
                    username: values.username,
                    password: values.password,
                    role: 'Customer',
                    status: values.status
                };
                result = await CustomerCMSService.updateCustomerCMS(obj);
            } else {
                const obj = {
                    email: values.email,
                    phone: values.phone,
                    fullName: values.fullName,
                    username: values.username,
                    password: values.password,
                    role: 'Customer',
                    status: values.status
                };
                result = await CustomerCMSService.createCustomerCMS(obj);
            }

            if (result.response?.data?.message === "success") {
                api.success({
                    message: editingItem ? 'Cập nhật thành công người dùng!' : 'Tạo thành công người dùng mới!',
                    showProgress: true,
                    pauseOnHover: true,
                });
            } else {
                api.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.response?.data?.message || 'Unknown error',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }

            fetchCustomerCMS();
            setIsModalOpen(false);
        } catch (error) {
            api.error({
                message: 'Vui lòng nhập đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };



    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchCustomerCMS = async () => {


        try {
            const result = await CustomerCMSService.getListCustomerCMS();
            // console.log(result.data, 'result')
            if (result.data.message === 'success') {
                setData(result.data.data);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
        fetchCustomerCMS();
    }, []);

    const columns = [

        // {
        //     title: 'Tên',
        //     dataIndex: 'fullName',
        //     key: 'fullName',
        // },
        {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            key: 'username',
        },
        // {
        //     title: 'Mật khẩu',
        //     dataIndex: 'password',
        //     key: 'password',
        // },
        {
            title: 'Tên đầy đủ',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                if (status === 'Active') {
                    return <div className='text-success'>Đã xác nhận</div>;
                } else if (status === 'Deactive') {
                    return <div className='text-colorThird'>Chưa xác nhận</div>;
                } else if (status === 'Deleted') {
                    return <div className='text-colorThird'>Đã xóa</div>;
                } else {
                    return 'Trạng thái không xác định';
                }
            },
        },
        {
            title: 'Ngày đăng ký',
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (text: string) => {
                const date = new Date(text);
                const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
                return date.toLocaleDateString('vi-VN', options); // Định dạng ngày tháng năm theo tiếng Việt
            },
        },

        // {
        //     title: 'Đường dẫn',
        //     dataIndex: 'slug',
        //     key: 'slug',
        // },
        {
            title: '',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link"
                        onClick={() => handleEdit(record)}
                    >
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const exportToExcel = () => {
        const filteredData = data.map(({ username, email, phone, fullName, createdTime, status, password }) => ({
            username,
            email,
            phone,  // Không cần định dạng lại phone bằng dấu "=" trong Excel
            fullName,
            createdTime,
            status,
            password
        }));

        const header = ['Username', 'Email', 'Phone', 'Full Name', 'Created Time', 'Status', 'Password'];

        // Chuyển đổi dữ liệu thành một array các đối tượng để tạo sheet
        const worksheetData = [header, ...filteredData.map(item => [
            item.username,
            item.email,
            item.phone,
            item.fullName,
            item.createdTime,
            item.status,
            item.password
        ])];

        // Tạo worksheet từ dữ liệu
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();

        // Thêm worksheet vào workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

        // Xuất file với định dạng .xlsx
        XLSX.writeFile(workbook, 'users.xlsx');
    };

    const handleExport = () => {
        exportToExcel();
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numericValue = value.replace(/[^\d]/g, '');
        event.target.value = numericValue;
    };

    return (
        <div>
            {contextHolder}
            <div className='flex justify-between'>
                <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                    Thêm người dùng
                </Button>
                <Button type="primary" onClick={handleExport} style={{ marginBottom: 16 }}>
                    Xuất file XLSX
                </Button>
            </div>

            <Table columns={columns} dataSource={data} rowKey="id" />

            <Modal
                title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'}
                open={isModalOpen}
                width={1000}
                onOk={handleOk}
                okText={editingItem ? 'Lưu chỉnh sửa' : 'Thêm mới ngay'}
                cancelText="Hủy"
                onCancel={handleCancel}>
                <Form form={form} layout="vertical">
                    <Form.Item name="fullName" label="Tên" rules={[{ required: true, message: 'Hãy nhập tên đầy đủ!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Hãy nhập email!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Số điện thoại"
                        rules={[
                            { required: true, message: 'Hãy nhập số điện thoại!' },
                            { pattern: /^[0-9]{10}$/, message: 'Số điện thoại phải gồm 10 chữ số' }
                        ]}
                    >
                        <Input
                            type="tel"
                            placeholder="Nhập số điện thoại"
                            onChange={handlePhoneChange}
                        />
                    </Form.Item>
                    <Form.Item name="username" label="Tên đăng nhập" rules={[{ required: true, message: 'Hãy nhập tên đăng nhập!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Mật khẩu"
                        name="password"
                        rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
                    >
                        <Input.Password
                            size="middle"
                            placeholder="Mật khẩu"
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Trạng thái"
                        rules={[{ required: true, message: 'Hãy chọn trạng thái!' }]}
                    >
                        <Select placeholder="Chọn trạng thái">
                            <Option value="Active">Đã xác nhận</Option>
                            <Option value="Deactivate">Chưa xác nhận</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageCustomerForm;
